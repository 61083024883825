<template>
  <div style="min-height: 420px">
    <v-data-table
      hide-default-footer
      :headers="headers"
      :items="items"
      :loading="loading"
      loading-text="Please wait...while we are loading"
      :items-per-page="item_per_page"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-center">{{ props.item.nick_name }}</td>
          <td class="text-center">{{ props.item.phone }}</td>
          <td class="text-center">{{ props.item.no_of_invite }}</td>
          <td class="text-center">
            <div style="color: #0019fe" v-if="props.item.sms_status">Sent</div>
            <div style="color:#D30024" v-else> Failed</div>
          </td>
          <td class="text-center"  v-if="props.item.invite_by!=='teacher'" >
            <v-btn
              class="text-capitalize"
              style="
                border: 1px solid #2c1963;
                background: #ffffff;
                border-radius: 8px;
              "
              @click="Resend(props.item)"
            >
              <span style="color: #2c1963">resend</span>
            </v-btn>
          </td>
           <td class="text-center"  v-else>
            <v-btn
              class="text-capitalize"
              style="
                border: 1px solid #2c1963;
                background: #ffffff;
                border-radius: 8px;
              "
              @click="ResendTeacher(props.item)"
            >
              <span style="color: #2c1963">resend</span>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:footer>
        <v-divider></v-divider>
        <div class="d-flex justify-center py-2">
          <v-btn
            style="border: 1px solid #000000; border-radius: 20px"
            class="text-capitalize px-10"
            text
            @click="showMore"
            :loading="loading"
            v-if="pageInfo.total_page>1"
          >
            <span style="letter-spacing: 0px">show more</span>
            <v-icon right>mdi-arrow-down</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { INVITE_VIA_SMS_LIST,INVITE_VIA_SMS_POST,INVITE_VIA_SMS_TEACHER_POST} from "@/constants/APIUrls";
import {mapGetters,mapActions} from "vuex"
export default {
  name: "PreviosInviteTemplate",
  data() {
    return {
      loading: false,
      formLoading:false,
      student_id:null,
         teacher_id:"",
      campaign_id:"",
      item_per_page:10,
      pageInfo:{},
      items: [],
      headers: [
        {
          sortable: false,
          text: "Name",
          align: "center",
          class: ["tableHeader-textModal", "tableHeader-bgModal"],
        },
        {
          sortable: false,
          text: "Phone",
          align: "center",
          class: ["tableHeader-textModal", "tableHeader-bgModal"],
        },
        {
          sortable: false,
          text: "# of Invites",
          align: "center",
          class: ["tableHeader-textModal", "tableHeader-bgModal"],
        },
        {
          sortable: false,
          text: "Status",
          align: "center",
          class: ["tableHeader-textModal", "tableHeader-bgModal"],
        },
        {
          sortable: false,
          text: "",
          align: "center",
          class: ["tableHeader-textModal", "tableHeader-bgModal"],
        },
      ],
    };
  },
  computed:{
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
       selectedCampaign: "campaign/getSelectedCampaign",
    }),
    getCampaignDetail(){
      return this.getCampaign
    },
      selectedCampaignData() {
      return this.selectedCampaign;
    },
  },
  watch:{},
  methods:{
    ...mapActions({
      toggleModal: "publicDonationPage/toggleInviteSms",
      showToast: "snackBar/showToast",
    }),
    showMore(){
      this.item_per_page = this.item_per_page+10
      this.getPreviousInviteList()
    },
    getPreviousInviteList(){
      this.loading = true
      const successHandler=(res)=>{
        console.log(res.data)
        this.items = res.data.invite_data
        this.pageInfo = res.data.page_info
        this.loading =false
      }
      const failureHandler=(res)=>{
        console.log(res)
        this.loading =false
      }
      let formData={}
      formData["student_id"]=this.student_id
      formData["invite_by"]="student"
      formData["campaign_id"] = this.getCampaignDetail.campaign_detail.id
      formData["detail_count"] = this.item_per_page
      Axios.request_GET(
        INVITE_VIA_SMS_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      )
    },
     getPreviousInviteTeacherList(){
      this.loading = true
      const successHandler=(res)=>{
        console.log(res.data)
        this.items = res.data.invite_data
        this.pageInfo = res.data.page_info
        this.loading =false
      }
      const failureHandler=(res)=>{
        console.log(res)
        this.loading =false
      }
      let formData={}
      formData["teacher_id"]=this.selectedCampaignData.organizationData.id;
      formData["invite_by"]="teacher"
      formData["campaign_id"] = this.campaign_id
      formData["detail_count"] = this.item_per_page
      Axios.request_GET(
        INVITE_VIA_SMS_LIST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      )
    },
    Resend(item) {
      const {nick_name,phone}=item;
      this.formLoading = true
      const successHandler = (res) => {
        console.log(res.data);
        this.showToast({
          message:"Resent Invite",
          color:"s"
        })
        this.getPreviousInviteList()
      this.formLoading = false
      };
      const failureHandler = (res) => {
        console.log(res);
      this.formLoading = false
      };
      let formData = {};
      formData["invite_by"] = "student";
      formData["platform"] = "sms";
      formData["campaign"] = this.getCampaignDetail.campaign_detail.id;
      formData["student"] = this.student_id;
      formData["phone_list"] = [{nick_name:nick_name,phone:phone}]
      formData["sms_template_id"] = 1
      Axios.request_POST(
        INVITE_VIA_SMS_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
     ResendTeacher(item) {
      const {nick_name,phone}=item;
      this.formLoading = true
      const successHandler = (res) => {
        console.log(res.data);
        this.showToast({
          message:"Resent Invite",
          color:"s"
        })
        this.getPreviousInviteTeacherList()
      this.formLoading = false
      };
      const failureHandler = (res) => {
        console.log(res);
      this.formLoading = false
      };
      let formData = {};
      formData["invite_by"] = "teacher";
      formData["platform"] = "sms";
      formData["campaign"] = this.campaign_id;
      formData["teacher"] = this.selectedCampaignData.organizationData.id;
      formData["phone_list"] = [{nick_name:nick_name,phone:phone}]
      formData["sms_template_id"] = 5
      Axios.request_POST(
        INVITE_VIA_SMS_TEACHER_POST,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted(){
    this.student_id = this.$route.query.student
     this.teacher_id = this.$route.query.teacher
     this.campaign_id= this.$route.query.campaign_id;
    console.log(this.getCampaignDetail)
     if(this.$route.matched[0].path.substring(1) !== 'teacher'){
    this.getPreviousInviteList()
    }
    if(this.$route.matched[0].path.substring(1) == 'teacher'){
     this.getPreviousInviteTeacherList()
    }
  }
};
</script>
<style>
.tableHeader-textModal {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 100.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bgModal {
  background: #e7e5ed !important;
}
</style>
